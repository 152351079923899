@use "@travellocal/ui/dist/import.scss" as *;

.subscribe {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;

  @include mobile {
    flex-direction: column;
    margin: 0;
  }
}

.subscribeColumn {
  padding: 2rem 4rem 2rem 4rem;
  flex-direction: row;
  grid-column: 2 / span 10;

  @media (max-width: 1024px) {
    align-items: center;
    grid-column: 1 / span 12;
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    padding: 4rem 0 0;
    align-items: flex-start;
  }
}
